import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Container } from '../blocks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Get Started`}</h1>
    <h1>{`Glossary`}</h1>
    <h1>{`Metaverse Press Syndicate`}</h1>
    <h1>{`The Oracle`}</h1>
    <h1>{`Contributions`}</h1>
    <h1>{`Collectors`}</h1>
    <h1>{`Artists`}</h1>
    <h1>{`Authors`}</h1>
    <h1>{`Shit posters`}</h1>
    <h1>{`Influencers`}</h1>
    <h1>{`Degenerates`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      